import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import { BasicCard } from '../../BasicCard'
import { Heading, Style, Variant } from '../../Heading'
import { Markdown } from '../../Markdown'
import { Link, Link as LinkComponent, LinkVariant } from '../../Link'
import { AmbassadorType } from '../../../lib/strapi/responseTypes'
import { ThumbnailCard } from '../../ThumbnailCard'
import { BioCard } from '../../BioCardGrids/BioCard'
import nextId from 'react-id-generator'

export type AmbassadorGridCardZoneType = DynamicBlockZone<
  'ComponentGridAmbassadorCard',
  {
    ambassador: AmbassadorType
  }
>

export const AmbassadorGridCardZoneFragment = `
  ... on ComponentGridAmbassadorCard {
    __typename
    ambassador {
        data {
            attributes {
                name
                bio
                sport
                Socials {
                    website
                    facebook
                    linkedin
                    instagram
                    twitter
                }
                profilePic {
                    data {
                        attributes {
                            alternativeText
                            url
                        }
                    }
                }
                backgroundPic {
                    data {
                        attributes {
                            alternativeText
                            url
                        }
                    }
                }
                ambassadorLink {
                    label
                    href
                    variant
                }
            }
        }
    }
  }
`

const AmbassadorGridCardZone = (props : AmbassadorGridCardZoneType) => {
  return (
    <BioCard key={nextId()} {...props.ambassador} />
  )
}

export default AmbassadorGridCardZone
