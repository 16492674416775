import { format } from 'date-fns'

/**
 * Build full Strapi media URL.
 * @param mediaUrl a full URL or relative pathname to Strapi media
 * @returns if string begins with `/`, build URL to Strapi location with it. Else return `mediaUrl` unchanged
 */

export function getStrapiMedia(mediaUrl: string) {
  return mediaUrl.startsWith('/')
    ? `${process.env.NEXT_PUBLIC_STRAPI_API_URL}${mediaUrl}` ||
        `${'http://localhost:1337'}${mediaUrl}`
    : mediaUrl
}

/**
 * Convert a string in Strapi's date format to long-hand
 * @param date string in format `YYYY-MM-DD`
 * @returns date as a string like '1st January 2021'
 */
export function strapiDateToString(date: string) {
  const d = date.split('-').map((str) => parseInt(str))

  return format(new Date(d[0], d[1] - 1, d[2]), 'do MMMM yyyy')
}
