import { ReactNode } from 'react'
import Image from 'next/image'
import { Heading, Variant, Style } from './Heading'

export interface ImageSectionProps {
  imgSrc: StaticImageData | string
  alt: string
  title: string
  children: ReactNode
}
export function ImageSection({
  imgSrc,
  alt,
  title,
  children,
}: ImageSectionProps) {
  return (
    <div className="flex flex-wrap gap-y-4 pt-12 w-full md:pt-4">
      <div className="h-64 mx-0 overflow-hidden w-full md:h-auto md:mx-0 md:w-1/3">
        <Image src={imgSrc} 
        alt={alt} 
        placeholder="blur"
        loader={({ src }) => {
          return src;
        }} />
      </div>
      <div className="px-4 space-y-4 w-full md:w-2/3 lg:pl-24">
        <Heading variant={Variant.h3} cssStyle={Style.h3} headingText={title} />
        {children}
      </div>
    </div>
  )
}
