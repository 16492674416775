import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import i18next from 'i18next'
import '../i18n/i18n'

import { TextArea } from './forms'
import { Button, Variant as ButtonVariant } from './Button'

const FORM_NAME = 'ReportYourProgressForm'
const TEXT_AREA_ROW_COUNT = 5

enum FieldNames {
  progressTowardsYourPledgeTargets = 'progressTowardsYourPledgeTargets',
}

export const ReportYourProgressValidationSchema = yup.object().shape({
  progressTowardsYourPledgeTargets: yup
    .string()
    .required(i18next.t('messageRequiredField')),
})

export interface ReportYourProgressProps {
  progressTowardsYourPledgeTargets?: string
  textareaPlaceholder?: string
  reportYourProgressButtonText?: string
  reportYourProgressButtonVariant?: ButtonVariant
}

export function ReportYourProgress({
  progressTowardsYourPledgeTargets,
  textareaPlaceholder,
  reportYourProgressButtonText,
  reportYourProgressButtonVariant,
}: ReportYourProgressProps) {
  const { t: translate } = useTranslation()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Record<FieldNames, string>>({
    resolver: yupResolver(ReportYourProgressValidationSchema),
  })

  return (
    <div className="max-w-max-content-width">
      <div className="flex pb-4">
        <form
          id={FORM_NAME}
          name={FORM_NAME}
          onSubmit={handleSubmit((data) => data)}
          className="bg-white flex flex-col gap-y-6 px-14 py-16 shadow-lg w-full"
        >
          <span className="-mb-2 font-semibold text-lg">
            {progressTowardsYourPledgeTargets
              ? progressTowardsYourPledgeTargets
              : translate('progressTowardsYourPledgeTargets')}
          </span>
          <TextArea
            id={FieldNames.progressTowardsYourPledgeTargets}
            label={''}
            placeholder={
              textareaPlaceholder ? textareaPlaceholder : 'Placeholder'
            }
            hideLabel
            register={register(FieldNames.progressTowardsYourPledgeTargets)}
            required
            errorMessage={errors?.progressTowardsYourPledgeTargets?.message}
            rows={TEXT_AREA_ROW_COUNT}
          />
          <span>
            <Button
              translationKey={
                reportYourProgressButtonText
                  ? reportYourProgressButtonText
                  : 'reportYourProgress'
              }
              buttonLabelClassname="uppercase"
              variant={
                reportYourProgressButtonVariant
                  ? reportYourProgressButtonVariant
                  : ButtonVariant.PRIMARY
              }
              type="submit"
            />
          </span>
        </form>
      </div>
    </div>
  )
}
