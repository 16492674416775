import { useState, createRef } from 'react'
import { Dropdown, InputBox } from './forms'
import {
  Button,
  Variant as ButtonVariant,
  Size as ButtonSize,
  Icon as ButtonIcon,
} from './Button'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  NewsletterFormValidationSchema,
  Countries,
  FieldNames,
} from './NewsletterFormValidationSchema'
import { Captcha } from './forms/Captcha'
import HCaptcha from '@hcaptcha/react-hcaptcha'

const FORM_NAME = 'NewsletterSignUpForm'

export interface NewsletterFormProps {
  title: string
  paragraph: string
  headingId?: string
}
export function NewsletterForm({
  title,
  paragraph,
  headingId,
}: NewsletterFormProps) {
  const { t: translate } = useTranslation()
  const [isSubmitted, setSubmitted] = useState(false)
  const [pauseSubmit, setPauseSubmit] = useState(false)
  const [errorSubmitting, setErrorSubmitting] = useState(false)
  const captchaRef = createRef<HCaptcha>()
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Record<FieldNames, string>>({
    resolver: yupResolver(NewsletterFormValidationSchema),
  })
  const regionDropdownWatch = watch('region')
  async function signUp(data: any) {
    setPauseSubmit(true)
    const res = await fetch('https://powcontactus.azurewebsites.net/api/NewsletterSignUp?code=lZZJvBfAVPLtSqQ0Lwsj2yPIGuZkUB5817mjIaQYTgeKAzFurmx8EA==', {
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
    if (res.status !== 200) {
      setErrorSubmitting(true)
    } else {
      setSubmitted(true)
    }
    setPauseSubmit(false)
  }

  return (
    <div className="col-span-12 flex justify-center">
      <div className="bg-white flex flex-wrap gap-8 justify-center max-w-916px mb-5 p-10 shadow-lg md:flex-nowrap">
        <div className="space-y-8 w-full md:w-1/2">
          <h3 id={headingId}>{title}</h3>
          <p>{paragraph}</p>
        </div>
        <form
          id={FORM_NAME}
          name={FORM_NAME}
          onSubmit={handleSubmit((data) => {
            captchaRef?.current?.execute()
            signUp(data).then(() => {
              setSubmitted(true)
              captchaRef?.current?.resetCaptcha()
            })
          })}
          className="flex flex-col gap-y-8 w-full md:w-1/2"
        >
          <div className="flex flex-col gap-y-8 w-full sm:flex-row sm:gap-x-8">
            <InputBox
              id={FieldNames.firstName}
              label={translate(FieldNames.firstName)}
              placeholder={translate(FieldNames.firstName)}
              register={register(FieldNames.firstName)}
              className="w-full"
              errorMessage={errors?.firstName?.message}
              hideLabel
            />
            <InputBox
              id={FieldNames.lastName}
              label={translate(FieldNames.lastName)}
              placeholder={translate(FieldNames.lastName)}
              register={register(FieldNames.lastName)}
              className="w-full"
              required
              errorMessage={errors?.lastName?.message}
              hideLabel
            />
          </div>
          <div className="flex flex-col gap-y-6 w-full md:flex-row md:gap-x-8">
            <InputBox
              id={FieldNames.emailAddress}
              label={translate(FieldNames.emailAddress)}
              placeholder={translate(FieldNames.emailAddress)}
              register={register(FieldNames.emailAddress)}
              className="w-full"
              required
              errorMessage={errors?.emailAddress?.message}
              hideLabel
            />
          </div>
          <Dropdown
            id={FieldNames.region}
            label={translate(FieldNames.region)}
            placeholder={translate(FieldNames.region)}
            register={register(FieldNames.region)}
            className="w-full"
            defaultValue=""
            hideLabel
            errorMessage={errors?.region?.message}
          >
            <option value="" disabled hidden>
              {translate('selectRegion')}
            </option>
            {Object.values(Countries).map((region) => (
              <option key={region} value={region}>
                {translate(region)}
              </option>
            ))}
          </Dropdown>
          <InputBox
            type={regionDropdownWatch === Countries.Other ? 'text' : 'hidden'}
            defaultValue=""
            id={FieldNames.otherRegion}
            label={translate('otherRegionRequiredField')}
            register={register(FieldNames.otherRegion)}
            className="w-full"
            required
            errorMessage={errors?.otherRegion?.message}
          />
          <Captcha
            onVerify={(token: string) => {
              setValue(FieldNames.captchaToken, token, {
                shouldValidate: true,
              })
            }}
            errorMessage={errors?.captchaToken?.message}
          />

          <span className="space-x-4">
            <Button
              translationKey="signUp"
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.STANDARD}
              icon={ButtonIcon.NONE}
              disabled={isSubmitted || pauseSubmit}
              type="submit"
              buttonLabelClassname="uppercase"
            />
            {!!isSubmitted && (
              <span>{translate('thanksForNewsletterSignUp')}</span>
            )}
            {!!errorSubmitting && (
              <span>{translate('errorSubmittingMessage')}</span>
            )}
          </span>
        </form>
      </div>
    </div>
  )
}
