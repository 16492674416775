import * as React from 'react'
import Image from 'next/image'
import { useTranslation } from 'react-i18next'

export interface HeaderBannerProps {
  headingTranslationKey?: string
  imgSrc: StaticImageData | string
  alternativeText?: string
  width?: number
  height?: number
}

export function HeaderBanner({
  imgSrc,
  alternativeText,
  headingTranslationKey,
  width,
  height,
}: HeaderBannerProps) {
  const { t: translate } = useTranslation()
  return (
    <div className="flex justify-center z-0">
      <div className="bg-gray flex justify-center max-w-max-footer-width w-full">
        <div className="bg-gray max-w-max-content-width p-4 relative w-full">
          <div className="min-h-278px relative">
            <Image
              className="min-h-278px object-cover z-0"
              src={imgSrc}
              alt={alternativeText ? alternativeText : 'Header Banner Image'}
              loader={({ src }) => {
                return src;
              }}
              {...(typeof imgSrc === 'string'
                ? { objectFit: 'cover', width: width, height: height }
                : { placeholder: 'blur' })}
              layout="fill"
              objectFit="cover"
              priority={true}
            />
          </div>
          {!!headingTranslationKey && (
            <h1
              id="main"
              className="-mt-1/2 bg-gray bottom-1.5 inline-block pr-4 pt-2 relative z-10 md:bottom-0 xl:ml-12 xl:pl-4"
            >
              {translate(headingTranslationKey)}
            </h1>
          )}
        </div>
      </div>
    </div>
  )
}
