import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import { getStrapiMedia } from '../../../lib/strapi/helpers'
import { ImageType } from '../../../lib/strapi/responseTypes'
import { ImageComponent, ImageVariant } from '../../Image'

export type LandscapeImageZoneType = DynamicBlockZone<
  'ComponentMediaTypesImageLandscape',
  {
      image: ImageType
  }
>

export const LandscapeImageZoneFragment = `
  ... on ComponentMediaTypesImageLandscape {
        __typename
          image {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
      }
`

const LandscapeImageZone = (props: LandscapeImageZoneType) => {
  return (
    <div className="flex w-full justify-center mb-4 mt-20 relative">
        <ImageComponent
          imgSrc={getStrapiMedia(props.image.data.attributes.url)}
          altText={props.image.data.attributes.alternativeText}
          variant={ImageVariant.INLINE}
          containerClassName="max-w-2xl"
        />
    </div>
  )
}

export default LandscapeImageZone
