import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import { ContentCard } from '../../ContentCard'
import { Heading, Style, Variant } from '../../Heading'
import { Markdown } from '../../Markdown'
import { Link, LinkVariant } from '../../Link'
import { ImageType, LinkType } from '../../../lib/strapi/responseTypes'
import { getStrapiMedia } from '../../../lib/strapi/helpers'

export type ContentCardZoneType = DynamicBlockZone<
  'ComponentContentCardsContentCard',
  {
    title: string
    content: string
    linkText: string
    href: string
    cardImage: ImageType
    isTextPositionOriginal:boolean
  }
>

export const ContentCardZoneFragment = `
... on ComponentContentCardsContentCard {
  __typename
  title
  content
  linkText
  href
  cardImage {
    data {
      attributes {
        url
        alternativeText
      }
    }
  }
  isTextPositionOriginal
}
`

const ContentCardZone = ({
  title,
  content,
  cardImage,
  linkText,
  href,
  isTextPositionOriginal,
}: ContentCardZoneType) => {
  return (
    <ContentCard
      imgSrc={getStrapiMedia(cardImage.data.attributes.url)}
      imgAlt={cardImage.data.attributes.alternativeText}
      isTextPositionOriginal={isTextPositionOriginal}
      content=""
    >
      {title && (
        <Heading variant={Variant.h3} cssStyle={Style.h3} headingText={title} />
      )}
      <div>
        <Markdown source={content} isForBlog={false} />
      </div>
      {linkText && (
        <Link href={href} label={linkText} variant={LinkVariant.FORWARD} />
      )}
    </ContentCard>
  )
}

export default ContentCardZone
