import { ReactNode } from 'react'
import cn from 'classnames'
import { VideoPlayer } from './VideoPlayer'

export interface CardVideoProps {
  videoSrc: string
  isTextPositionOriginal?: boolean
  title?: string
  content: string
  children?: ReactNode
  contentClassnames?: string
  isAutoLooping?: boolean
  isControlled?: boolean
}

export function ContentCardVideo({
  videoSrc,
  isControlled = true,
  isAutoLooping = false,
  isTextPositionOriginal = true,
  title,
  content,
  children,
  ...props
}: CardVideoProps) {
  const videoClassName = cn(
    'flex items-center z-0 max-h-72 overflow-hidden',
    'sm:max-w-lg sm:mx-auto',
    'sm:max-h-80',
    ' md:max-w-xl',
    'lg:z-20 lg:w-568px lg:h-432px'
  )

  return (
    <div className="items-center md:flex-col-reverse lg:flex lg:flex-row">
      {isTextPositionOriginal && (
        <div className={videoClassName}>
          <VideoPlayer
            videoSrc={videoSrc}
            isControlled={isControlled}
            isAutoLooping={isAutoLooping}
          />
        </div>
      )}

      <div
        className={cn(
          'bg-white px-10 py-12 shadow-contentCardShadow z-10 mx-auto',
          'sm:max-w-md ',
          'md:max-w-lg',
          'lg:max-w-full lg:w-6/12 lg:pl-13% lg:ml-4',
          props.contentClassnames,
          isTextPositionOriginal
            ? 'lg:mb-20 lg:translate-y-20 lg:pl-20 lg:-ml-12 \
              md:mt-0 md:mb-0 z-10'
            : 'lg:-mr-20 lg:ml-4"-0 lg:mb-20 lg:translate-y-20 \
              md:-mt-16 md:mb-0  \
              sm:-mb-16'
        )}
      >
        {!!title && <h3 className="pb-4">{title}</h3>}
        {!!content && <p>{content}</p>}
        {children && (
          <div
            className={cn(
              'flex flex-col flex-wrap pt-6 gap-y-6 md:flex-row md:gap-x-8',
              !!title || !!content
            )}
          >
            {children}
          </div>
        )}
      </div>
      {!isTextPositionOriginal && (
        <div className={videoClassName}>
          <VideoPlayer
            videoSrc={videoSrc}
            isControlled={isControlled}
            isAutoLooping={isAutoLooping}
          />
        </div>
      )}
    </div>
  )
}
