import { ComponentType } from 'react'
import NullableOptional from '../../types/nullableOptional'

export function stripNullableProperties<T extends {}>(
  obj: NullableOptional<T>
): T {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v !== null)
  ) as T
}

export function zoneFromComponent<ZonePropsType, ComponentPropsType>(
  Component: ComponentType<ComponentPropsType>,
  mapProps: (props: ZonePropsType) => ComponentPropsType
): (props: ZonePropsType) => JSX.Element {
  return (props: ZonePropsType) => <Component {...mapProps(props)} />
}

export function mapPropsIdentity<
  ZonePropsType extends ComponentPropsType,
  ComponentPropsType
>(props: ZonePropsType) {
  const propsWithoutNull = stripNullableProperties<ZonePropsType>(props)
  return propsWithoutNull as ComponentPropsType
}
