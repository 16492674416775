import { gql } from 'graphql-request'
import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import { Heading, Style, Variant } from '../../Heading'
import { TextBlock } from '../../TextBlock'
import { AthleteFeature, AthleteData } from '../../AthleteFeature'

export type AthleteFeatureZoneType = DynamicBlockZone<
  'ComponentContentAthleteFeature',
  {
    ambassadors: AthleteData
  }
>

export const AthleteFeatureZoneFragment = gql`
... on ComponentContentAthleteFeature {
  __typename
  ambassadors {
    data {
      attributes {
    name
    bio
    sport
    profilePic {
      data {
        attributes {
          alternativeText
          url
        }
      }
    }
    backgroundPic {
      data {
        attributes {
          alternativeText
          url
        }
      }
    }
    ambassadorLink {
      href
      variant
      label
    }
    Socials {
      instagram
      facebook
      website
      linkedin
      twitter
    }
  }
  }
}
}
`

const AthleteFeatureZone = (props: AthleteFeatureZoneType) => {
  return (
    <TextBlock>
      <AthleteFeature athletes={props.ambassadors}></AthleteFeature>
    </TextBlock>
  )
}

export default AthleteFeatureZone
