import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import { TextBlock } from '../../TextBlock'
import { Heading, Variant, Style } from '../../Heading'
import { Markdown } from '../../Markdown'
import { Link } from '../../Link'
import { LinkType, HeadingType } from '../../../lib/strapi/responseTypes'

export type TextBlockZoneType = DynamicBlockZone<
  'ComponentContentTextBlock',
  {
    heading: HeadingType
    sectionText: string
  }
>

export const TextBlockZoneFragment = `
   ... on ComponentContentTextBlock {
        __typename
        heading {
          headingText
        }
        sectionText 
      }
`

const TextBlockZone = (props: TextBlockZoneType) => {
  return (
    <TextBlock>
      {props.heading && (
        <Heading
          className="mb-8"
          variant={Variant.h2}
          cssStyle={Style.h2}
          headingText={props.heading.headingText}
        />
      )}
      <Markdown source={props.sectionText} isForBlog={false} />
    </TextBlock>
  )
}

export default TextBlockZone
