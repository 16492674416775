import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import { Style, Variant as HeadingVariant } from '../../Heading'
import { Variant as ButtonVariant } from '../../Button'
import { SetYourTarget } from '../../SetYourTarget'

export type SetYourTargetZoneType = DynamicBlockZone<
  'ComponentPledgeSetYourTarget',
  {
    heading: {
      title: string
      headingVariant: HeadingVariant
      headingStyle: Style
    }
    firstTextareaHeading: string
    secondTextareaHeading: string
    saveProgressButton: {
      label: string
      variant: ButtonVariant
    }
    saveAndReviewButton: {
      label: string
      variant: ButtonVariant
    }
  }
>

export const SetYourTargetZoneFragment = `
  ... on ComponentPledgeSetYourTarget {
    __typename
    heading {
      title
      headingVariant
      headingStyle 
    }
    firstTextareaHeading
    secondTextareaHeading
    saveProgressButton {
      label
      variant 
    }
    saveAndReviewButton {
      label
      variant 
    }
}
`

const SetYourTargetZone = (props: SetYourTargetZoneType) => {
  return (
    <SetYourTarget
      headingText={props.heading.title}
      headingVariant={props.heading.headingVariant}
      headingStyle={props.heading.headingStyle}
      firstTextareaHeading={props.firstTextareaHeading}
      secondTextareaHeading={props.secondTextareaHeading}
      saveProgressButtonText={props.saveProgressButton.label}
      saveProgressButtonVariant={props.saveProgressButton.variant}
      saveAndReviewButtonText={props.saveAndReviewButton.label}
      saveAndReviewButtonVariant={props.saveAndReviewButton.variant}
    />
  )
}

export default SetYourTargetZone
