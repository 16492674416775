import { ComponentType } from 'react'
import { DynamicBlockZoneNames } from '../../lib/strapi/dynamicBlockZones'
import FeedbackOnThePledgeZone from './Zones/FeedbackOnThePledgeZone'
import BillingHistoryZone from './Zones/BillingHistoryZone'
import HeaderBannerZone from './Zones/HeaderBannerZone'
import ButtonZone from './Zones/ButtonZone'
import BasicCardZone from './Zones/BasicCardZone'
import ImageSectionZone from './Zones/ImageSectionZone'
import ContentCardZone from './Zones/ContentCardZone'
import AthleteFeatureZone from './Zones/AthleteFeatureZone'
import MetaTagsZone from './Zones/MetaTagsZone'
import {
  Heading1Zone,
  Heading2Zone,
  Heading3Zone,
  Heading4Zone,
} from './Zones/HeadingsZone'
import TextBlockZone from './Zones/TextBlockZone'
import SetYourTargetZone from './Zones/SetYourTargetZone'
import ReportYourProgressZone from './Zones/ReportYourProgressZone'
import NewsLetterFormZone from './Zones/ComponentFormNewsLetterForm'
import PartnerSectionZone from './Zones/PartnerSectionZone'
import LandscapeImageZone from './Zones/LandscapeImageZone'
import PortraitImageZone from './Zones/PortraitImageZone'
import TextTrioZone from './Zones/TextTrioZone'
import MerchandiseCardZone from './Zones/MerchandiseCardZone'
import TabZone from './Zones/TabZone'
import GridZone from './Zones/GridZone'
import SendItPostcardZone from './Zones/SendItPostcardZone'
import GridCardZone from './Zones/GridCardZone'
import AmbassadorGridCardZone from './Zones/AmbassadorGridCard'
import VideoZone from './Zones/VideoZone'
import VideoCardZone from './Zones/VideoCardZone'
import SendItLetterZone from './Zones/SendItLetterZoneType'

type StrapiDynamicZoneMapperProps = {
  dynamicZoneName: DynamicBlockZoneNames
} & unknown

const zoneMap: {
  [dynamicZoneName in DynamicBlockZoneNames]: ComponentType<any>
} = {
  ComponentMarketingHeaderBanner: HeaderBannerZone,
  ComponentAtomsButton: ButtonZone,
  ComponentContentCardsBasicCard: BasicCardZone,
  ComponentContentCardsImageSection: ImageSectionZone,
  ComponentContentCardsContentCard: ContentCardZone,
  ComponentContentAthleteFeature: AthleteFeatureZone,
  ComponentHeaderMetaTags: MetaTagsZone,
  ComponentHeadingsH1: Heading1Zone,
  ComponentHeadingsH2: Heading2Zone,
  ComponentHeadingsH3: Heading3Zone,
  ComponentHeadingsH4: Heading4Zone,
  ComponentContentTextBlock: TextBlockZone,
  ComponentPledgeSetYourTarget: SetYourTargetZone,
  ComponentPledgeFeedbackOnThePledge: FeedbackOnThePledgeZone,
  ComponentPledgeBillingHistory: BillingHistoryZone,
  ComponentPledgeReportYourProgress: ReportYourProgressZone,
  ComponentFormNewsLetterForm: NewsLetterFormZone,
  ComponentContentPartnerSection: PartnerSectionZone,
  ComponentMediaTypesImageLandscape: LandscapeImageZone,
  ComponentMediaTypesImagePortrait: PortraitImageZone,
  ComponentContentTextTrio: TextTrioZone,
  ComponentContentCardsMerchandiseCard: MerchandiseCardZone,
  ComponentTabsTabContainer: TabZone,
  ComponentGridGridContainer: GridZone,
  ComponentFormSendItPostcard: SendItPostcardZone,
  ComponentFormSendItLetter: SendItLetterZone,
  ComponentGridGridCard: GridCardZone,
  ComponentGridAmbassadorCard : AmbassadorGridCardZone,
  ComponentMediaTypesVideo: VideoZone,
  ComponentContentCardsVideo: VideoCardZone,
}

/** A component that accepts a Strapi dynamic zone type and maps it to the correct component */
const StrapiZoneMapper = ({
  dynamicZoneName,
  ...props
}: StrapiDynamicZoneMapperProps) => {
  const Component = zoneMap[dynamicZoneName]

  // Check if a matching component exists and return it
  if (!Component) {
    console.error(
      `Unrecognized Strapi zone! No React component found for ${dynamicZoneName}`
    )
    return null
  }

  return <Component {...props} />
}

export default StrapiZoneMapper
