import { gql } from 'graphql-request'
import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import StrapiZoneMapper from '../StrapiZoneMapper'

import { TextBlock } from '../../TextBlock'
import { Tabs } from '../../BioCardGrids/Tabs'
import { useState } from 'react'
import { GridZoneFragment } from './GridZone'
import { TabsType } from '../../../lib/strapi/responseTypes'


export type TabZoneType = DynamicBlockZone<
  'ComponentTabsTabContainer',
  {
    tabs: TabsType
  }
>

export const TabZoneFragment = gql`
   ... on ComponentTabsTabContainer {
    __typename
        tabs {
            data {
                attributes {
                    title 
                    description
                    children {
                    ${GridZoneFragment}
                    }
                }
            }
        }
      }
`

export default function TabZone(props: TabZoneType) {
    const [selectedTabTitle, setSelectedTabTitle] = useState(props.tabs.data[0].attributes.title)

    return (
      <TextBlock>
        <Tabs
          selectedTabTitle={selectedTabTitle}
          setSelectedTabTitle={setSelectedTabTitle}
          tabTitles={props.tabs.data.map((tab) => tab.attributes.title)}
        />
        <p className="py-8 md:mx-32">
          {props.tabs.data.find((tab) => tab.attributes.title === selectedTabTitle)?.attributes.description}
        </p>
        { props.tabs.data.find((tab) => tab.attributes.title === selectedTabTitle)?.attributes.children.map((child) => (
          <div>
            <StrapiZoneMapper
            key={ child.__typename}
            dynamicZoneName={child.__typename}
            {...child}
          />
          </div>
        ))}

        
      </TextBlock>
    )
}

