import cn from 'classnames'

export interface SelectionIndicatorProps {
  maxIndex: number
  selected: number
}
export function SelectionIndicator({
  maxIndex,
  selected,
}: SelectionIndicatorProps) {
  return (
    <div className="flex justify-center pt-4 w-full">
      {Array.from(Array(maxIndex).keys()).map((key) => (
        <div
          key={key}
          className={cn('border-2 border-primary h-2 inline-flex mx-1 w-2', {
            'bg-primary': key === selected,
          })}
        />
      ))}
    </div>
  )
}
