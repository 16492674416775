import React, { ReactNode } from 'react'
import Image from 'next/image'
import cn from 'classnames'

export interface ThumbnailCardProps {
  title: string
  subtitle?: string
  alt: string
  imgSrc: string | StaticImageData
  children?: ReactNode
  link?: ReactNode
  headingClassnames?: string
}
export function ThumbnailCard({
  title,
  subtitle,
  alt,
  imgSrc,
  children,
  link,
  ...props
}: ThumbnailCardProps) {
  return (
    <div className="bg-white max-w-60 mt-32 p-3 relative shadow-lg">
      <div className="-mt-32 max-w-md overflow-hidden">
        <Image
          src={imgSrc}
          alt={alt}
          objectFit="cover"
          width={600}
          height={400}
          loader={({ src }) => {
            return src;
          }}
        />
      </div>
      <h4 className={props.headingClassnames}>{title}</h4>
      {!!subtitle && (
        <p className={cn({ 'pb-12': !children && link })}>{subtitle}</p>
      )}
      {!!children && (
        <span className={cn('pt-4 block', !!link && 'pb-12')}>{children}</span>
      )}
      {!!link && <span className="absolute bottom-3 break-words">{link}</span>}
    </div>
  )
}

export interface StaffCardContainerProps {
  children: ReactNode
}
export function StaffCardContainer({ children }: StaffCardContainerProps) {
  return (
    <div className="auto-rows-auto gap-x-4 gap-y-4 grid grid-cols-2 pb-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
      {children}
    </div>
  )
}

export interface ThreeThumbnailContainerProps {
  children: ReactNode
}
export function ThreeThumbnailCardContainer({
  children,
}: ThreeThumbnailContainerProps) {
  return (
    <div className="flex justify-center w-full">
      <div className="auto-rows-auto gap-x-8 gap-y-4 grid-cols-1 pb-4 w-2/3 sm:grid md:grid-cols-3 md:w-full">
        {children}
      </div>
    </div>
  )
}
