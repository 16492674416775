import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import { TextTrio, TextTrioItem } from '../../TextTrio'
import { LinkType, HeadingType } from '../../../lib/strapi/responseTypes'
import { Heading, Style, Variant } from '../../Heading'
import { Markdown } from '../../Markdown'
import { VideoPlayer } from '../../VideoPlayer'
import { ContentCardVideo } from '../../ContentCardVideo'
import { Link, LinkVariant } from '../../Link'

export type VideoCardZoneType = DynamicBlockZone<
  'ComponentContentCardsVideo',
  {
    videoSrc: string
    title: string
    content: string
    autoloop: boolean
    link: LinkType
  }
>

export const VideoCardZoneFragment = `
   ... on ComponentContentCardsVideo {
        __typename
        videoSrc
        title
        content
        autoloop
        link {
            label
            href
        }
      }
`

const VideoCardZone = (props: VideoCardZoneType) => {
  return (
    <ContentCardVideo 
    videoSrc={props.videoSrc} 
    content={props.content}
    title={props.title} 
    >
        {props.link && 
         <Link href={props.link.href} label={props.link.label} variant={LinkVariant.FORWARD} />
        }
        </ContentCardVideo>
  )
}

export default VideoCardZone
