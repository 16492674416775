import * as yup from 'yup'
import i18next from 'i18next'
import '../i18n/i18n'

export enum FieldNames {
  firstName = 'firstName',
  lastName = 'lastName',
  emailAddress = 'emailAddress',
  region = 'region',
  otherRegion = 'otherRegion',
  captchaToken = 'captchaToken',
}

export enum Countries {
  England = 'england',
  EuMainland = 'euMainland',
  NorthernIreland = 'northernIreland',
  Scotland = 'scotland',
  Wales = 'wales',
  Other = 'other',
}
export const NewsletterFormValidationSchema = yup.object().shape({
  firstName: yup.string().required(i18next.t('firstNameRequiredField')),
  lastName: yup.string().required(i18next.t('lastNameRequiredField')),
  emailAddress: yup
    .string()
    .required(i18next.t('emailRequiredField'))
    .email(i18next.t('emailInvalidField')),
  region: yup
    .string()
    .required()
    .oneOf(Object.values(Countries), i18next.t('regionRequiredField')),
  otherRegion: yup.string().when('region', {
    is: 'other',
    then: yup.string().required(i18next.t('otherRegionRequiredField')),
  }),
  captchaToken: yup.string().required(i18next.t('captchaRequiredField')),
})
