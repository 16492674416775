import Image from 'next/image'
import { ReactNode, useState } from 'react'

import { ScrollButton } from './ScrollButton'
import { SelectionIndicator } from './SelectionIndicator'
import { getStrapiMedia } from '../lib/strapi/helpers'
import { Link, LinkVariant } from './Link'
import { ImageType } from '../lib/strapi/responseTypes'

export type AthleteData = {
  data: [AthleteProps]
}

export type AthleteProps = {
  attributes : {
    profilePic: ImageType
    backgroundPic: ImageType
    name: string
    bio: string
    sport: string
    socials: {
      instagram: string
      facebook: string
      website: string
      linkedin:string
      twitter:string
    }
    ambassadorLink: {
      href: string
      label: string
      variant: LinkVariant
    }
  }
}

export interface AthleteFeatureProps {
  athletes: AthleteData
}

export function AthleteFeature({ athletes }: AthleteFeatureProps) {
  const [selected, setSelected] = useState(0)
  const maxIndex = athletes.data.length - 1

  function rightHandler() {
    if (selected === maxIndex) {
      setSelected(0)
    } else {
      setSelected(selected + 1)
    }
  }
  function leftHandler() {
    if (selected === 0) {
      setSelected(maxIndex)
    } else {
      setSelected(selected - 1)
    }
  }
  return (
    <div className="col-span-12 flex flex-col justify-center pb-8">
      <div className="flex items-center justify-center mt-7 relative sm:full-grid">
        <span className="flex justify-center sm:col-span-1 md:col-start-1">
          <ScrollButton direction="left" onClick={leftHandler} />
        </span>
        <span className="sm:col-span-4 md:col-span-10">
          <AthleteSingle
            primaryImageData={getStrapiMedia(athletes.data[selected].attributes.profilePic.data.attributes.url)}
            primaryImageAlt={athletes.data[selected].attributes.profilePic.data.attributes.alternativeText}
            secondaryImageData={getStrapiMedia(athletes.data[selected].attributes.backgroundPic.data.attributes.url)}
            secondaryImageAlt={
              athletes.data[selected].attributes.backgroundPic.data.attributes.alternativeText
            }
            name={athletes.data[selected].attributes.name}
            bio={athletes.data[selected].attributes.bio}
          >
            <Link
              variant={athletes.data[selected].attributes.ambassadorLink.variant}
              translationKey={athletes.data[selected].attributes.ambassadorLink.label}
              href={athletes.data[selected].attributes.ambassadorLink.href}
            />
          </AthleteSingle>
        </span>
        <span className="flex justify-center sm:col-span-1">
          <ScrollButton direction="right" onClick={rightHandler} />
        </span>
      </div>
      <SelectionIndicator maxIndex={maxIndex + 1} selected={selected} />
    </div>
  )
}

export interface Athlete {
  primaryImageData: StaticImageData | string
  primaryImageAlt: string
  secondaryImageData: StaticImageData | string
  secondaryImageAlt: string
  name: string
  bio: string
  children?: ReactNode
}

interface AthleteImageProps {
  imgSrc: StaticImageData | string
  alt: string
}

function AthleteSingle({
  primaryImageData,
  primaryImageAlt,
  secondaryImageData,
  secondaryImageAlt,
  name,
  bio,
  children,
}: Athlete) {
  const AthleteImage = ({ imgSrc, alt }: AthleteImageProps) => (
    <Image
      src={typeof imgSrc === 'string' ? getStrapiMedia(imgSrc) : imgSrc}
      alt={alt}
      {...(typeof imgSrc === 'string'
        ? { objectFit: 'cover', width: 670, height: 500 }
        : { placeholder: 'blur' })}
        loader={({ src }) => {
          return src;
        }}
    />
  )

  return (
    <div className="flex flex-wrap justify-center relative w-full lg:flex-nowrap">
      <div className="w-full md:w-1/2 lg:max-h-full lg:max-w-2xl lg:w-full">
        <AthleteImage imgSrc={primaryImageData} alt={primaryImageAlt} />
      </div>
      <div className="w-full md:w-1/2 lg:max-h-full lg:max-w-xs lg:pl-4 lg:w-full">
        <AthleteImage imgSrc={secondaryImageData} alt={secondaryImageAlt} />
      </div>
      <div className="bg-white px-10 py-10 shadow-contentCardShadow w-full md:-bottom-60 lg:-bottom-8 lg:-right-3 lg:absolute lg:h-2/3 lg:w-96">
        <div className="h-full relative">
          <div className="pb-8">
            <h3>{name}</h3>
            {bio}
          </div>
          <div className="bottom-0 lg:absolute">{children}</div>
        </div>
      </div>
    </div>
  )
}
