import { ButtonHTMLAttributes } from 'react'
import cn from 'classnames'
import { Chevron as ChevronIcon } from './icons'

export interface ScrollButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  direction: 'left' | 'right'
}
export function ScrollButton({ direction, ...props }: ScrollButtonProps) {
  return (
    <button
      className={cn(
        'px-2 flex cursor-pointer text-3xl sm:text-4xl lg:text-6xl ring-focus focus:ring-2 rounded',
        {
          '-rotate-90': direction === 'right',
          'rotate-90': direction === 'left',
        }
      )}
      {...props}
      aria-label={`Scroll button ${direction}`}
    >
      <ChevronIcon className="inline" />
    </button>
  )
}
