import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import { getStrapiMedia } from '../../../lib/strapi/helpers'
import { ImageType } from '../../../lib/strapi/responseTypes'
import { HeaderBanner } from '../../HeaderBanner'

export type HeaderBannerZoneType = DynamicBlockZone<
  'ComponentMarketingHeaderBanner',
  {
    Heading: string
    BackgroundImage: ImageType
  }
>

// TODO: we can get multiple different sizes and formats from the images provided by Strapi - we should use these for better rendering across devices
export const HeaderBannerZoneFragment = `
  ... on ComponentMarketingHeaderBanner {
    __typename
    Heading
    BackgroundImage {
      data {
        attributes {
          alternativeText
          url
          height
          width
        }
      }
    }
  }
`

const HeaderBannerZone = ({
  Heading,
  BackgroundImage,
}: HeaderBannerZoneType) => {
  return (
    <HeaderBanner
      headingTranslationKey={Heading}
      imgSrc={getStrapiMedia(BackgroundImage.data.attributes.url)}
      alternativeText={BackgroundImage.data.attributes.alternativeText}
      width={BackgroundImage.data.attributes.width}
      height={BackgroundImage.data.attributes.height}
    />
  )
}

export default HeaderBannerZone
