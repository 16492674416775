import { gql } from 'graphql-request'
import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import { NewsletterFormProps, NewsletterForm } from '../../NewsletterForm'
import { mapPropsIdentity, zoneFromComponent } from '../StrapiZoneFactory'

export type NewsLetterFormZoneType = DynamicBlockZone<
  'ComponentFormNewsLetterForm',
  {
    title: string
    paragraph: string
  }
>

export const NewsLetterFormFragment = gql`
... on ComponentFormNewsLetterForm {
    __typename
    paragraph
    title
  }
`

const NewsLetterFormZone = zoneFromComponent<NewsLetterFormZoneType, NewsletterFormProps>(
    NewsletterForm,
  mapPropsIdentity
)

export default NewsLetterFormZone

