import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import nextId from 'react-id-generator'

interface BillingHistoryProps {
  billingHistories: {
    date: string
    details: string
    amount: string
    downloadLink: {
      invoiceDownloadUrl: string
      invoiceDownloadText: string
      vatReceiptDownloadUrl: string
      vatReceiptDownloadText: string
    }
  }[]
}

export function BillingHistory({ billingHistories }: BillingHistoryProps) {
  const { t: translate } = useTranslation()

  return (
    <div>
      <div className="md:hidden">
        <div>
          <span className="bg-white block p-4 uppercase">
            {translate('date')}
          </span>
          {billingHistories
            .map((billing) => billing.date)
            .map((date) => (
              <span key={nextId()} className="block px-4 py-6">
                {date}
              </span>
            ))}
        </div>
        <div>
          <span className="bg-white block p-4 uppercase">
            {translate('details')}
          </span>
          {billingHistories
            .map((billing) => billing.details)
            .map((details) => (
              <span key={nextId()} className="block px-4 py-6">
                {details}
              </span>
            ))}
        </div>
        <div>
          <span className="bg-white block p-4 uppercase">
            {translate('amount')}
          </span>
          {billingHistories
            .map((billing) => billing.amount)
            .map((amount) => (
              <span key={nextId()} className="block px-4 py-6">
                {amount}
              </span>
            ))}
        </div>
        <div>
          <span className="bg-white block p-4 uppercase">
            {translate('download')}
          </span>
          {billingHistories
            .map((billing) => billing.downloadLink)
            .map((link) => (
              <div key={nextId()} className="px-4 py-6">
                <div>
                  <Link href={link.invoiceDownloadUrl}>
                    <a className="text-focus">{link.invoiceDownloadText}</a>
                  </Link>
                </div>
                <div>
                  <Link href={link.vatReceiptDownloadUrl}>
                    <a className="text-focus">{link.vatReceiptDownloadText}</a>
                  </Link>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="hidden md:block">
        <div className="bg-white grid grid-cols-4 p-4 uppercase">
          <span className="block">{translate('date')}</span>
          <span className="block">{translate('details')}</span>
          <span className="block">{translate('amount')}</span>
          <span className="block">{translate('download')}</span>
        </div>
        {billingHistories.map((billing) => (
          <div key={nextId()} className="grid grid-cols-4 px-4 py-6">
            <span className="block">{billing.date}</span>
            <span className="block">{billing.details}</span>
            <span className="block">{billing.amount}</span>
            <div>
              <div>
                <Link href={billing.downloadLink.invoiceDownloadUrl}>
                  <a className="text-focus">
                    {billing.downloadLink.invoiceDownloadText}
                  </a>
                </Link>
              </div>
              <div>
                <Link href={billing.downloadLink.vatReceiptDownloadUrl}>
                  <a className="text-focus">
                    {billing.downloadLink.vatReceiptDownloadText}
                  </a>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
