import ReactMarkdown from 'react-markdown'
import { getStrapiMedia } from '../lib/strapi/helpers'
import { Heading, Style, Variant } from './Heading'
import { ImageComponent, ImageVariant } from './Image'
import { Link, LinkVariant } from './Link'
import { List } from './List'
import Image from 'next/image'

// used to convert markdown-generated tags into relevant React components
const BlogMarkdownComponents: object = {
  img: (image: any) => {
    return (
      <div className="w-full sm:mt-0 md:mt-8">
        <ImageComponent
          variant={ImageVariant.INLINE}
          imgSrc={getStrapiMedia(image.src)}
          altText={image.alt}
        />
      </div>
    )
  },

  h1: (node: { children?: string }) => {
    return (
      !!node.children && (
        <Heading
          className="mx-auto normal-case"
          variant={Variant.h1}
          cssStyle={Style.h1}
          headingText={node.children}
        />
      )
    )
  },

  h2: (node: { children?: string }) => {
    return (
      !!node.children && (
        <Heading
          className="mb-8 mx-auto normal-case sm:mt-0 md:mt-10"
          variant={Variant.h2}
          cssStyle={Style.h2}
          headingText={node.children}
        />
      )
    )
  },

  h3: (node: { children?: string }) => {
    return (
      !!node.children && (
        <Heading
          className="mb-8 mx-auto sm:mt-0 md:mt-14"
          variant={Variant.h3}
          cssStyle={Style.h3}
          headingText={node.children}
        />
      )
    )
  },

  h4: (node: { children?: string }) => {
    return (
      !!node.children && (
        <Heading
          className="mb-8 mt-8 mx-auto"
          variant={Variant.h4}
          cssStyle={Style.h4}
          headingText={node.children}
        />
      )
    )
  },

  ul: (node: { children?: any }) => {
    return (
      <div className="mx-auto">
        <List variant="unordered">{node.children}</List>
      </div>
    )
  },

  li: (node: { children?: any }) => {
    return <li className="leading-6">{node.children}</li>
  },

  ol: (node: { children?: any }) => {
    return (
      <div className="mx-auto">
        <List variant="ordered">{node.children}</List>
      </div>
    )
  },

  p: (node: { children?: any }) => {
    return <div className="mx-auto pb-6">{node.children}</div>
  },

  a: (node: any) => {
    return (
      <Link
        label={node.children}
        href={node.href}
        variant={LinkVariant.SUBTLE}
        allowWrap={true}
      />
    )
  },
}

const MarkdownComponents: object = {
  img: (image: any) => {
    return (
      <div className="relative w-full">
        <Image
          src={getStrapiMedia(image.src)}
          alt={image.alt}
          objectFit="cover"
          layout="fill"
          loader={({ src }) => {
            return src;
          }}
        />
      </div>
    )
  },

  h1: (node: { children?: string }) => {
    return (
      !!node.children && (
        <Heading
          variant={Variant.h1}
          cssStyle={Style.h1}
          headingText={node.children}
        />
      )
    )
  },

  h2: (node: { children?: string }) => {
    return (
      !!node.children && (
        <Heading
          variant={Variant.h2}
          cssStyle={Style.h2}
          headingText={node.children}
        />
      )
    )
  },

  h3: (node: { children?: string }) => {
    return (
      !!node.children && (
        <Heading
          variant={Variant.h3}
          cssStyle={Style.h3}
          headingText={node.children}
          className="mt-0"
        />
      )
    )
  },

  h4: (node: { children?: string }) => {
    return (
      !!node.children && (
        <Heading
          variant={Variant.h4}
          cssStyle={Style.h4}
          headingText={node.children}
        />
      )
    )
  },

  ul: (node: { children?: any }) => {
    return <List variant="unordered">{node.children}</List>
  },

  ol: (node: { children?: any }) => {
    return <List variant="ordered">{node.children}</List>
  },

  p: (node: { children?: any }) => {
    return <p>{node.children}</p>
  },

  a: (node: any) => {
    return (
      <Link
        label={node.children}
        href={node.href}
        variant={LinkVariant.SUBTLE}
        allowWrap={true}
      />
    )
  },
}

export interface MarkdownProps {
  source: string
  isForBlog?: boolean
}
export function Markdown({ source, isForBlog }: MarkdownProps) {
  return (
    <>
      {isForBlog ? (
        <ReactMarkdown children={source} components={BlogMarkdownComponents} />
      ) : (
        <ReactMarkdown children={source} components={MarkdownComponents} />
      )}
    </>
  )
}
