import { gql } from 'graphql-request'
import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import StrapiZoneMapper from '../StrapiZoneMapper'

import { GridData } from '../../../lib/strapi/responseTypes'
import { GridCardZoneFragment } from './GridCardZone'
import { AmbassadorGridCardZoneFragment } from './AmbassadorGridCard'

export type GridZoneType = DynamicBlockZone<
  'ComponentGridGridContainer',
  {
    grid_item: GridData
    isLarge: boolean
  }
>

export const GridZoneFragment = gql`
... on ComponentGridGridContainer {
  __typename
  isLarge
  grid_item {
    data {
      attributes {
    name
    items {
        ${GridCardZoneFragment}
        ${AmbassadorGridCardZoneFragment}
    }
   
  }
  }
}
}
`

export default function GridZone(props: GridZoneType) {
  var items = props.grid_item.data.attributes.items;
  var smallColumns = props.isLarge ? "2" : "3"
  var mediumColumns = props.isLarge ? "3" : "4"
  var largeColumns = props.isLarge ?  "3" : "5"
  return (
    <div className={`auto-rows-min gap-4 grid grid-cols-1 grid-flow-row pb-4 sm:grid-cols-${smallColumns} md:grid-cols-${mediumColumns} lg:grid-cols-${largeColumns} lg:gap-x-6`}>
    {items.map( (item, index) =>
        <div className='flex flex-col'>
           <StrapiZoneMapper
            key={item.__typename}
            dynamicZoneName={item.__typename}
            {...item}
          />
          </div>
    )}

</div>
  )
}


