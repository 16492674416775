import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import { Variant } from '../../Button'
import { FeedbackOnThePledge } from '../../FeedbackOnThePledge'

export type FeedbackOnThePledgeZoneType = DynamicBlockZone<
  'ComponentPledgeFeedbackOnThePledge',
  {
    headingText: {
      title: string
    }
    text: string
    button: {
      label: string
      variant: Variant
    }
  }
>

export const FeedbackOnThePledgeZoneFragment = `
  ... on ComponentPledgeFeedbackOnThePledge {
    __typename
    headingText {
      title 
    }
    text 
    button {
      label 
      variant 
    }
  }
`

const FeedbackOnThePledgeZone = (props: FeedbackOnThePledgeZoneType) => {
  return (
    <FeedbackOnThePledge
      headingText={props.headingText.title}
      text={props.text}
      buttonText={props.button.label}
    />
  )
}

export default FeedbackOnThePledgeZone
