import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import { BasicCard } from '../../BasicCard'
import { Heading, Style, Variant } from '../../Heading'
import { Markdown } from '../../Markdown'
import { Link as LinkComponent, LinkVariant } from '../../Link'

export type BasicCardZoneType = DynamicBlockZone<
  'ComponentContentCardsBasicCard',
  {
    title: string
    content: string
    hasYellowBorder: boolean
    Link: {
      href: string
      variant: LinkVariant
      label: string
    }
  }
>

export const BasicCardZoneFragment = `
  ... on ComponentContentCardsBasicCard {
    __typename
    title
    content
    hasYellowBorder
    Link {
      href
      variant
      label
    }
  }
`

const BasicCardZone = ({
  title,
  content,
  hasYellowBorder,
  Link,
}: BasicCardZoneType) => {
  return (
    <BasicCard hasYellowBorder={hasYellowBorder}>
      <Heading variant={Variant.h3} cssStyle={Style.h3} headingText={title} />
      <div>
        <Markdown source={content} isForBlog={false} />
      </div>
      {Link && (
        <p>
          <LinkComponent
            variant={Link.variant}
            label={Link.label}
            href={Link.href}
          />
        </p>
      )}
    </BasicCard>
  )
}

export default BasicCardZone
