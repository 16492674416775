import { ReactNode } from 'react'

export interface BasicCardProps {
  children: ReactNode
  hasYellowBorder?: boolean
}
export function BasicCard({ children, hasYellowBorder = true }: BasicCardProps) {
  return (
    <div className="col-span-12 relative">
      <div className="bg-white px-12 py-10 relative shadow-lg space-y-8 w-full z-10">
        {children}
      </div>
     {
       hasYellowBorder &&  <div className="-left-2 -top-2 absolute bg-quaternary-60 h-32 rounded-lg w-1/2" />
     }
    </div>
  )
}
