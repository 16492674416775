import Image from 'next/image'
import cn from 'classnames'

export enum ImageVariant {
  HEADER_BANNER = 'headerBanner',
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
  SQUARE = 'square',
  INLINE = 'inline',
}

export interface ImageProps {
  imgSrc: string
  altText: string
  variant: ImageVariant
  containerClassName?: string
}

// https://dev.to/tanahmed/next-image-make-image-fill-available-space-272o
const inlineImageOverrideCss = `
  .inline-img {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }

  .unset-img {
    width: 100%;
  }
  .unset-img > span {
    position: unset !important;
  }

`

export function ImageComponent({
  imgSrc,
  altText,
  variant,
  containerClassName,
}: ImageProps) {
  return (
    <>
      <style>{inlineImageOverrideCss}</style>
      {variant === ImageVariant.HEADER_BANNER && (
        <div className={cn(containerClassName, 'min-h-278px relative')}>
          <Image
            className="min-h-278px object-cover z-0"
            src={imgSrc}
            alt={altText}
            placeholder="blur"
            layout="fill"
            objectFit="cover"
            priority
            loader={({ src }) => {
              return src;
            }}
          />
        </div>
      )}
      {variant === ImageVariant.INLINE && (
        <div className={cn(containerClassName, 'unset-img')}>
          <Image
            className="inline-img"
            src={imgSrc}
            alt={altText}
            layout="fill"
            loader={({ src }) => {
              return src;
            }}
          />
        </div>
      )}
      {variant === ImageVariant.PORTRAIT && (
        <div className={cn(containerClassName, 'max-w-800px relative')}>
          <Image
            src={imgSrc}
            alt={altText}
            placeholder="blur"
            objectFit="cover"
            loader={({ src }) => {
              return src;
            }}
          />
        </div>
      )}
      {variant === ImageVariant.SQUARE && (
        <div
          className={cn(containerClassName, 'min-h-184px min-w-184px relative')}
        >
          <Image src={imgSrc}  
          alt={altText} 
          placeholder="blur"
          loader={({ src }) => {
            return src;
          }} />
        </div>
      )}
      {variant === ImageVariant.LANDSCAPE && (
        <div className={cn(containerClassName, 'relative')}>
          <Image
            src={imgSrc}
            alt={altText}
            placeholder="blur"
            objectFit="cover"
            layout="fill"
            loader={({ src }) => {
              return src;
            }}
          />
        </div>
      )}
    </>
  )
}
