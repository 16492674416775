import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import { getStrapiMedia } from '../../../lib/strapi/helpers'
import { ImageType } from '../../../lib/strapi/responseTypes'
import Image from 'next/image'

export type PortraitImageZoneType = DynamicBlockZone<
  'ComponentMediaTypesImagePortrait',
  {
      image: ImageType
  }
>

export const PortraitImageZoneFragment = `
  ... on ComponentMediaTypesImagePortrait {
        __typename
          image {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
      }
`

const PortraitImageZone = (props: PortraitImageZoneType) => {
  return (
    <div className="h-46 max-w-350px mb-4 mt-20 relative">
            <Image
              src={getStrapiMedia(props.image.data.attributes.url)}
              alt={props.image.data.attributes.alternativeText}
              objectFit="contain"
              layout="fill"
              loader={({ src }) => {
                return src;
              }}
            />
          </div>
  )
}

export default PortraitImageZone
