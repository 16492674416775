import { useState } from 'react'
import { Link, LinkVariant } from '../Link'
import { ThumbnailCard } from '../ThumbnailCard'
import { Heading, Variant, Style } from '../Heading'
import {
  Instagram as InstagramIcon,
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Cross as CrossIcon,
  Youtube as YoutubeIcon,
  LinkedIn as LinkedInIcon,
} from '../icons'
import Image from 'next/image'
import cn from 'classnames'
import nextId from 'react-id-generator'
import { AmbassadorType } from '../../lib/strapi/responseTypes'
import { getStrapiMedia } from '../../lib/strapi/helpers'

export function BioCard(ambassador: AmbassadorType) {
  const [showFullBio, setShowFullBio] = useState(false)
  const focusRingClasses = 'focus:ring-2 focus:ring-focus rounded-sm'
  const [delayHandler, setDelayHandler] = useState(
    setTimeout(() => {
      /*do nothing*/
    }, 0)
  )
  function handleFocus() {
    clearTimeout(delayHandler)
  }
  function handleBlur() {
    setDelayHandler(
      setTimeout(() => {
        setShowFullBio(false)
      }, 0)
    )
  }
  return (
    <div className='flex flex-1'>
      <ThumbnailCard
        title={ambassador.data.attributes.name}
        subtitle={ambassador.data.attributes.sport}
        alt={ambassador.data.attributes.profilePic.data.attributes.alternativeText}
        imgSrc={ambassador.data.attributes.profilePic.data.attributes.url}
        link={
          <Link
            variant={LinkVariant.SUBTLE}
            href="#"
            label={`Read ${ambassador.data.attributes.name}'s Bio`}
            tabIndex={-1}
            allowWrap
            onClick={(event) => { 
              event.preventDefault()
              setShowFullBio(true)
            }}
          />
        }
      >
        {ambassador.data.attributes.bio.split(".")[0]}
      </ThumbnailCard>
      {showFullBio && (
        <div
          className="bg-white fixed h-full left-0 opacity-70 top-0 w-full z-30"
          onClick={() => setShowFullBio(false)}
        />
      )}
      {showFullBio && (
        <div className="-translate-x-1/2 bg-white fixed left-1/2 max-h-screen overflow-y-scroll overscroll-y-contain pb-12 shadow-lg top-0 w-4/5 z-40 md:pb-0">
          <div className="flex px-8 w-full">
            <button
              className={cn(
                'absolute cursor-pointer right-8 rounded-sm text-3xl top-8 z-10',
                focusRingClasses
              )}
              onClick={() => setShowFullBio(false)}
              aria-label="Close button"
            >
              <CrossIcon />
            </button>
            <div className="bg-gray h-2px relative top-20 w-full" />
          </div>
          <div className="flex flex-col gap-8 h-full p-8 pt-28 w-full md:flex-row">
            <div className="space-y-4 w-full md:w-1/3" onBlur={handleBlur}>
              <div className="max-h-96 overflow-hidden w-full">
                <Image
                  src={ambassador.data.attributes.profilePic.data.attributes.url}
                  alt={ambassador.data.attributes.profilePic.data.attributes.alternativeText}
                  width={300}
                  height={400}
                  loader={({ src }) => {
                    return src;
                  }}
                />
              </div>
              <div className="flex gap-x-4">
                {ambassador.data.attributes.Socials[0].instagram && (
                  <a
                    href={`${ambassador.data.attributes.Socials[0].instagram}`}
                    className={cn('text-3xl', focusRingClasses)}
                    aria-label={`${ambassador.data.attributes.name}'s Instagram`}
                    onFocus={handleFocus}
                  >
                    <InstagramIcon />
                  </a>
                )}
                {ambassador.data.attributes.Socials[0].facebook && (
                  <a
                    href={`${ambassador.data.attributes.Socials[0].facebook}`}
                    className={cn('text-3xl', focusRingClasses)}
                    aria-label={`${ambassador.data.attributes.name}'s Facebook`}
                    onFocus={handleFocus}
                  >
                    <FacebookIcon />
                  </a>
                )}
                {ambassador.data.attributes.Socials[0].linkedin && (
                  <a
                    href={`${ambassador.data.attributes.Socials[0].linkedin}`}
                    className={cn('text-3xl', focusRingClasses)}
                    aria-label={`${ambassador.data.attributes.name}'s LinkedIn`}
                    onFocus={handleFocus}
                  >
                    <LinkedInIcon />
                  </a>
                )}
              </div>
            </div>
            <div className="space-y-4 w-full lg:w-2/3">
              <Heading
                variant={Variant.h3}
                cssStyle={Style.h3}
                headingText={`${ambassador.data.attributes.name}`}
              />
              {ambassador.data.attributes.bio.split(".").map((line) => (
                <p key={nextId()}>{line}</p>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>

  )
}
