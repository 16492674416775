import { ReactNode } from 'react'
import cn from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { Heading, Variant, Style } from './Heading'

export interface CardProps {
  imgSrc: StaticImageData | string
  imgAlt: string
  isTextPositionOriginal?: boolean
  title?: string
  content: string
  children: ReactNode
  contentClassnames?: string
  cardHref?: string
}

export function ContentCard({
  imgSrc,
  imgAlt,
  isTextPositionOriginal = true,
  title = '',
  content,
  children,
  cardHref,
  ...props
}: CardProps) {
  const image = (
    <Image
      src={imgSrc}
      alt={imgAlt}
      {...(typeof imgSrc === 'string'
        ? { objectFit: 'cover', width: 600, height: 400 }
        : { placeholder: 'blur' })}
        loader={({ src }) => {
          return src;
        }}
    />
  )

  const imageClassnames = cn(
    'sm:mx-auto max-h-72 sm:max-h-96 overflow-hidden row-start-1 lg:max-h-screen lg:my-auto lg:pt-7px',
    'col-span-4',
    'sm:col-span-6',
    'md:col-start-2 md:col-span-10',
    'lg:col-span-7',
    isTextPositionOriginal ? 'lg:col-start-1' : 'lg:col-start-6'
  )

  return (
    <div className="full-grid grid-rows-1">
      {isTextPositionOriginal && (
        <div className={imageClassnames}>
          {cardHref ? (
            <Link href={cardHref}>
              <a>{image}</a>
            </Link>
          ) : (
            image
          )}
        </div>
      )}
      <div
        className={cn(
          'lg:row-start-1 flex items-center lg:col-span-6 md:col-span-8 sm:col-span-6 col-span-4 sm:mx-10 md:mx-0 col-start-1 z-10 row-start-2 -mt-14 lg:my-auto',
          isTextPositionOriginal
            ? 'lg:col-start-7 md:col-start-3'
            : 'lg:col-start-1 md:col-start-3'
        )}
      >
        <div
          className={cn(
            'bg-white px-10 py-12 shadow-contentCardShadow',
            props.contentClassnames
          )}
        >
          {!!title &&
            (cardHref ? (
              <Link href={cardHref}>
                <a>
                  <Heading
                    className="mb-4"
                    variant={Variant.h3}
                    cssStyle={Style.h3}
                    headingText={title}
                  />
                </a>
              </Link>
            ) : (
              <a>
                <Heading
                  className="mb-4"
                  variant={Variant.h3}
                  cssStyle={Style.h3}
                  headingText={title}
                />
              </a>
            ))}
          {!!content && <p>{content}</p>}
          {children && (
            <div
              className={cn(
                'flex flex-col flex-wrap pt-6 gap-y-6 md:flex-row md:gap-x-8',
                !!title || !!content
              )}
            >
              {children}
            </div>
          )}
        </div>
      </div>
      {!isTextPositionOriginal && (
        <div className={imageClassnames}>
          {cardHref ? (
            <Link href={cardHref}>
              <a>{image}</a>
            </Link>
          ) : (
            image
          )}
        </div>
      )}
    </div>
  )
}

export interface ContentCardContainerProps {
  children: ReactNode
}
export function ContentCardContainer({ children }: ContentCardContainerProps) {
  return (
    <div className="flex flex-col justify-center space-y-20 w-full md:space-y-10">
      {children}
    </div>
  )
}
