import { ReactNode } from "react";
interface MerchandiseCardProps {
    children: ReactNode
}
export default function MerchandiseCard({children}:MerchandiseCardProps) {
  return (
   <div className="gap-x-4 gap-y-6 grid pt-4 md:grid-cols-2 lg:grid-cols-3">
      {
        children
      }
    </div>
  )
}