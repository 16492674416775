import { gql } from 'graphql-request'
import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import { Button, ButtonProps, Variant, Size } from '../../Button'
import { mapPropsIdentity, zoneFromComponent } from '../StrapiZoneFactory'

export type ButtonZoneType = DynamicBlockZone<
  'ComponentAtomsButton',
  {
    label?: string
    variant?: Variant
    disabled?: boolean
    className?: string
    buttonLabelClassname?: string
    size?: Size
  }
>

export const ButtonZoneFragment = gql`
... on ComponentAtomsButton {
  __typename
  label
  variant
  disabled
  className
  buttonLabelClassname
  size
}
`

const ButtonZone = zoneFromComponent<ButtonZoneType, ButtonProps>(
  Button,
  mapPropsIdentity
)

export default ButtonZone
