import { forEach } from 'cypress/types/lodash'
import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import { getStrapiMedia } from '../../../lib/strapi/helpers'
import { Partner, Partners } from '../../../lib/strapi/responseTypes'
import { Heading, Style, Variant } from '../../Heading'
import { PartnerLogo } from '../../PartnerLogo'
import { TextBlock } from '../../TextBlock'

export type PartnerSectionZoneType = DynamicBlockZone<
  'ComponentContentPartnerSection',
  {
      sectionTitle: string
      partners: Partners
  }
>

export const PartnerSectionZoneFragment = `
  ... on ComponentContentPartnerSection {
    __typename
    sectionTitle
    partners {
      data {
        attributes {
          partnerImage {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          partnerName
          partnerLink {
            href
          }
        }
      }
    }
}
`

const PartnerSectionZone = (props: PartnerSectionZoneType) => {
  return (
    <TextBlock>
    <Heading
      className="mb-7 mt-20"
      variant={Variant.h2}
      cssStyle={Style.h2}
      headingText={props.sectionTitle}
    />
    <div className="gap-x-8 gap-y-6 grid grid-cols-2 sm:grid-cols-3 md:gap-x-16 md:grid-cols-4">
      {props.partners.data.map((partner) => (
        <PartnerLogo
          key={partner.attributes.partnerLink.href}
          url={partner.attributes.partnerLink.href}
          src={getStrapiMedia(partner.attributes.partnerImage.data.attributes.url)}
          alt={partner.attributes.partnerImage.data.attributes.alternativeText}
        />
      ))}
    </div>
  </TextBlock>
  )
}

export default PartnerSectionZone
