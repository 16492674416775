import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import i18next from 'i18next'
import '../i18n/i18n'

import { TextArea } from './forms'
import { Heading, Style, Variant as HeadingVariant } from './Heading'
import { Button, Variant as ButtonVariant } from './Button'

const FORM_NAME = 'SetYourTargetForm'
const TEXT_AREA_ROW_COUNT = 5

enum FieldNames {
  howClimateAffectBussiness = 'howClimateAffectBussiness',
  organisationsPlan = 'organisationsPlan',
}

export const SetYourTargetValidationSchema = yup.object().shape({
  howClimateAffectBussiness: yup
    .string()
    .required(i18next.t('fieldRequiredField')),
  organisationsPlan: yup.string().required(i18next.t('fieldRequiredField')),
})

export interface SetYourTargetProps {
  headingText?: string
  headingVariant?: HeadingVariant
  headingStyle?: Style
  firstTextareaHeading?: string
  secondTextareaHeading?: string
  saveProgressButtonText?: string
  saveProgressButtonVariant?: ButtonVariant
  saveAndReviewButtonText?: string
  saveAndReviewButtonVariant?: ButtonVariant
}

export function SetYourTarget({
  headingText,
  headingVariant,
  headingStyle,
  firstTextareaHeading,
  secondTextareaHeading,
  saveProgressButtonText,
  saveProgressButtonVariant,
  saveAndReviewButtonText,
  saveAndReviewButtonVariant,
}: SetYourTargetProps) {
  const { t: translate } = useTranslation()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Record<FieldNames, string>>({
    resolver: yupResolver(SetYourTargetValidationSchema),
  })

  return (
    <div className="max-w-max-content-width">
      <div className="max-w-screen-lg mx-auto text-center">
        {headingText && (
          <Heading
            className="mb-12"
            headingText={
              headingText ? headingText : translate('setTargetForYourBussiness')
            }
            variant={headingVariant ? headingVariant : HeadingVariant.h4}
            cssStyle={headingStyle ? headingStyle : Style.h4}
          />
        )}
      </div>
      <div className="flex pb-4">
        <form
          id={FORM_NAME}
          name={FORM_NAME}
          onSubmit={handleSubmit((data) => data)}
          className="bg-white flex flex-col gap-y-6 px-14 py-16 shadow-lg w-full"
        >
          <span className="-mb-2 font-semibold text-lg">
            {firstTextareaHeading
              ? firstTextareaHeading
              : translate('HowWillClimateBreakdownAffectYourBussiness')}
          </span>
          <TextArea
            id={FieldNames.howClimateAffectBussiness}
            label={''}
            hideLabel
            register={register(FieldNames.howClimateAffectBussiness)}
            required
            errorMessage={errors?.howClimateAffectBussiness?.message}
            rows={TEXT_AREA_ROW_COUNT}
          />
          <span className="-mb-2 font-semibold text-lg">
            {secondTextareaHeading
              ? secondTextareaHeading
              : translate('WhatPlansAreYourOrganisationMaking')}
          </span>
          <TextArea
            id={FieldNames.organisationsPlan}
            label={''}
            hideLabel
            register={register(FieldNames.organisationsPlan)}
            required
            errorMessage={errors?.organisationsPlan?.message}
            rows={TEXT_AREA_ROW_COUNT}
          />
          <div className="flex flex-col mt-3 md:flex-row">
            <span className="block md:mr-8">
              <Button
                label={saveProgressButtonText}
                translationKey="saveProgress"
                variant={
                  saveAndReviewButtonVariant
                    ? saveProgressButtonVariant
                    : ButtonVariant.OUTLINED_LIGHT_BACKGROUND
                }
                buttonLabelClassname="uppercase"
                type="submit"
              />
            </span>
            <span>
              <Button
                label={saveAndReviewButtonText}
                translationKey="saveAndReview"
                buttonLabelClassname="uppercase"
                variant={
                  saveAndReviewButtonVariant
                    ? saveAndReviewButtonVariant
                    : ButtonVariant.PRIMARY
                }
                type="submit"
              />
            </span>
          </div>
        </form>
      </div>
    </div>
  )
}
