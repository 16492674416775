import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import { Variant as ButtonVariant } from '../../Button'
import { ReportYourProgress } from '../../ReportYourProgress'

export type ReportYourProgressZoneType = DynamicBlockZone<
  'ComponentPledgeReportYourProgress',
  {
    progressTowardsYourPledgeTargets: string
    textareaPlaceholder: string
    button: {
      label: string
      variant: ButtonVariant
    }
  }
>

export const ReportYourProgressZoneFragment = `
  ... on ComponentPledgeReportYourProgress {
    __typename
    progressTowardsYourPledgeTargets
    textareaPlaceholder
    button {
      label
      variant 
    } 
}
`

const ReportYourProgressZone = (props: ReportYourProgressZoneType) => {
  return (
    <ReportYourProgress
      progressTowardsYourPledgeTargets={props.progressTowardsYourPledgeTargets}
      textareaPlaceholder={props.textareaPlaceholder}
      reportYourProgressButtonText={props.button.label}
      reportYourProgressButtonVariant={props.button.variant}
    />
  )
}

export default ReportYourProgressZone
