import { ReactNode } from 'react'
import cn from 'classnames'

export interface ListProps {
  children: ReactNode
  variant?: 'unordered' | 'ordered' | 'none'
}
export function List({ children, variant = 'unordered' }: ListProps) {
  const classes = cn('pl-4', {
    'list-none': variant === 'none',
    'list-disc': variant === 'unordered',
    'list-decimal': variant === 'ordered',
  })
  if (variant === 'ordered') {
    return <ol className={classes}>{children}</ol>
  } else {
    return <ul className={classes}>{children}</ul>
  }
}
