import Image from 'next/image'
import Link from 'next/link'
import { Button, Variant, Size, Icon } from './Button'

interface POWProductProps {
  imgSrc: string
  imgAlt?: string
  productName: string
  price: string
  buyingLink: string
  isOutOfStock: boolean
}

export function POWProduct({
  imgSrc,
  imgAlt,
  productName,
  price,
  buyingLink,
  isOutOfStock,
}: POWProductProps) {
  return (
    <div className="flex flex-col items-center">
      <Link href={buyingLink}>
        <a rel="noopener noreferrer" target="_blank">
          <div>
            <Image src={imgSrc} 
            alt={imgAlt}
            height='550px' width='550px'
            loader={({ src }) => {
              return src;
            }} />
          </div>
          <div className="flex flex-col items-center">
            <span className="block font-medium hover:underline mb-2 mt-3 text-base">
              {productName}
            </span>
            <span className="block font-normal mb-6 text-2xl">	£{price}</span>
          </div>
        </a>
      </Link>
      <div className="mb-4">
        <Link href={buyingLink}>
          <a rel="noopener noreferrer" target="_blank">
            <Button
              buttonLabelClassname="uppercase"
              variant={isOutOfStock ? Variant.TERTIARY : Variant.PRIMARY}
              size={Size.STANDARD}
              icon={Icon.NONE}
              translationKey={isOutOfStock ? 'outOfStock' : 'buyNow'}
            />
          </a>
        </Link>
      </div>
    </div>
  )
}
