import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import { TextTrio, TextTrioItem } from '../../TextTrio'
import { LinkType, HeadingType } from '../../../lib/strapi/responseTypes'
import { Heading, Style, Variant } from '../../Heading'
import { Markdown } from '../../Markdown'

export type TextTrioZoneType = DynamicBlockZone<
  'ComponentContentTextTrio',
  {
    Items: [TextTrioItem]
  }
>

type TextTrioItem = {
    title: string
    content: string
}

export const TextTrioZoneFragment = `
   ... on ComponentContentTextTrio {
        __typename
       Items {
        title
        content
       }
      }
`

const TextTrioZone = (props: TextTrioZoneType) => {
  return (
    <TextTrio>
        {props.Items.map((trioItem) => (
             <TextTrioItem>
             <Heading
               variant={Variant.h3}
               cssStyle={Style.h3}
               headingText={trioItem.title}
               className="pb-4"
             />
             <Markdown source={trioItem.content} isForBlog={false} />
           </TextTrioItem>  
        ))}
    </TextTrio>
  )
}

export default TextTrioZone
