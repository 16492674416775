
import { SendItWrapper } from '../components/SendItWrapper'
import LogoImage from '../public/assets/pages/send-it/POW_UK_Logo_Primary_Blue 1.png'
import StampImage from '../public/assets/pages/send-it/Stamp.png'
import Image from 'next/image'
import { TextBlock } from './TextBlock'
import { Markdown } from './Markdown'

export interface SendItPostcardProps {
    body: string
  }

export default function SendItPostcard({body}: SendItPostcardProps) {
    return (
        <>
               <div className='container mx-auto rounded overflow-hidden shadow-2xl md:bg-card-texture bg-no-repeat bg-cover'>
               <div className="flex flex-row flex-wrap py-8">
               <main role="main" className="w-full md:w-1/3 sm:w-1/2 pt-1 px-2 relative">
                <div id="mobile logo" className='bg-card-mobile-texture bg-no-repeat bg-cover h-80 sm:visible md:hidden'>

                </div>
                <div className='flex mt-8 ml-8 hidden md:flex'>
                    <div className='w-max px-8'>
                            <Image
                                src={LogoImage}
                                className='w-max'
                                alt='POW Logo'
                                width="285"
                                loader={({ src }) => {
                                    return src;
                                }}
                            />
                            </div>
                            <div className='object-right'>
                            <Image
                                src={StampImage}
                                className='justify-end'
                                alt='POW Stamp'
                                width="205"
                                loader={({ src }) => {
                                    return src;
                                }}
                            />
                        </div>
                        </div>
                <div className='container md:border-r-2 border-gray-100 md:m-6'>
                <Markdown source={body} isForBlog={false} />
                </div>
                </main>
                <aside className="w-full md:w-2/3 sm:w-1/2 px-2">
            <SendItWrapper></SendItWrapper>
                 </aside>
      
    </div>
               </div>
        </>
    )
}


