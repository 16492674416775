import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import { Variant as ButtonVariant } from '../../Button'
import { ReportYourProgress } from '../../ReportYourProgress'
import SendItPostcard from '../../SendItPostcard'

export type SendItPostcardZoneType = DynamicBlockZone<
  'ComponentFormSendItPostcard',
  {
    Body: string
  }
>

export const SendItPostcardZoneFragment = `
  ... on ComponentFormSendItPostcard {
    __typename
    Body
}
`

const SendItPostcardZone = (props: SendItPostcardZoneType) => {
    return (
        <SendItPostcard body={props.Body}></SendItPostcard>
    )
}

export default SendItPostcardZone



