import { TextArea } from './forms'
import {
  Button,
  Variant as ButtonVariant,
  Size as ButtonSize,
  Icon as ButtonIcon,
} from './Button'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import i18next from 'i18next'
import { Heading, Style, Variant } from './Heading'

const FORM_NAME = 'FeedbackOnThePledgeForm'
const TEXT_AREA_ROW_COUNT = 6

enum FieldNames {
  feedbackMessage = 'feedbackMessage',
}

const FeedbackOnThePledgeFormValidationSchema = yup.object().shape({
  feedbackMessage: yup
    .string()
    .required(i18next.t('feedbackMessageRequiredField')),
})

interface FeedbackProps {
  headingText: string
  text: string
  buttonText: string
}

export function FeedbackOnThePledge({
  headingText,
  text,
  buttonText,
}: FeedbackProps) {
  const { t: translate } = useTranslation()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Record<FieldNames, string>>({
    resolver: yupResolver(FeedbackOnThePledgeFormValidationSchema),
  })

  async function submitFeedback(data: any) {
    return data
  }

  return (
    <div className="max-w-778px mx-auto pb-4 pt-12">
      <div>
        <Heading
          className="mb-12"
          headingText={headingText}
          cssStyle={Style.h3}
          variant={Variant.h3}
        />
        <div className="max-w-md mb-12">{text}</div>
      </div>
      <form
        id={FORM_NAME}
        name={FORM_NAME}
        onSubmit={handleSubmit((data) => submitFeedback(data))}
        className="flex flex-col gap-y-6 max-w-916px py-6 w-full"
      >
        <TextArea
          id="FeedbackBox"
          label={translate(FieldNames.feedbackMessage)}
          hideLabel
          register={register(FieldNames.feedbackMessage)}
          required
          errorMessage={errors?.feedbackMessage?.message}
          rows={TEXT_AREA_ROW_COUNT}
        />
        <span className="block mt-12">
          <Button
            className="uppercase"
            label={buttonText}
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.STANDARD}
            icon={ButtonIcon.NONE}
            type="submit"
          />
        </span>
      </form>
    </div>
  )
}
