import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import { getStrapiMedia } from '../../../lib/strapi/helpers'
import { ImageType } from '../../../lib/strapi/responseTypes'
import MetaTags from '../../MetaTags'

export type MetaTagsZoneType = DynamicBlockZone<
  'ComponentHeaderMetaTags',
  {
    pageTitle: string
    pageRelativeURL: string
    description: string
    image: {
      masthead: ImageType
    }
  }
>

export const MetaTagsZoneFragment = `
  ... on ComponentHeaderMetaTags {
        __typename
        pageTitle
        pageRelativeURL
        description
        image {
          masthead {
            data {
              attributes {
                url
              }
            }
          }
        }
  }
`

const MetaTagsZone = (props: MetaTagsZoneType) => {
  return (
    <MetaTags
      pageTitle={props.pageTitle}
      pageRelativeURL={props.pageRelativeURL}
      description={props.description}
      imageURL={getStrapiMedia(props.image.masthead.data.attributes.url)}
      imageAltText={props.image.masthead.data.attributes.alternativeText}
    />
  )
}

export default MetaTagsZone
