import cn from 'classnames'
import nextId from 'react-id-generator'

export interface TabsProps {
  tabTitles: string[]
  selectedTabTitle: string
  setSelectedTabTitle: (tab: string) => void
}
export function Tabs({
  tabTitles,
  selectedTabTitle,
  setSelectedTabTitle,
}: TabsProps) {
  return (
    <span className="flex flex-row justify-center space-x-4 w-full">
      {tabTitles.map((tabTitle) => (
        <button
          key={nextId()}
          onClick={() => setSelectedTabTitle(tabTitle)}
          className={cn(
            'font-bold text-2xl cursor-pointer focus:ring-2 focus:ring-focus rounded-sm',
            selectedTabTitle !== tabTitle && 'text-primary-60'
          )}
        >
          {tabTitle}
          {selectedTabTitle === tabTitle && (
            <div className={cn('bg-primary h-2px relative  w-full')} />
          )}
        </button>
      ))}
    </span>
  )
}
