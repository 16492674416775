import { ReactNode } from 'react'

export interface TextTrioProps {
  children: ReactNode
}
export function TextTrio({ children }: TextTrioProps) {
  return (
    <div className="flex flex-wrap gap-y-4 w-full md:space-y-0">{children}</div>
  )
}

export interface TextTrioItemProps {
  children: ReactNode
}
export function TextTrioItem({ children }: TextTrioItemProps) {
  return <div className="pb-4 pr-4 space-y-4 w-full md:w-1/3">{children}</div>
}
