import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import { BillingHistory } from '../../BillingHistory'

export type BillingHistoryZoneType = DynamicBlockZone<
  'ComponentPledgeBillingHistory',
  {
    allBillingHistories: {
      date: string
      details: string
      amount: string
      downloadLink: {
        invoiceDownloadText: string
        invoiceDownloadUrl: string
        vatReceiptDownloadText: string
        vatReceiptDownloadUrl: string
      }
    }[]
  }
>

export const BillingHistoryZoneFragment = `
  ... on ComponentPledgeBillingHistory {
    __typename
    allBillingHistories {
      date
      details
      amount
      downloadLink {
        invoiceDownloadText
        invoiceDownloadUrl
        vatReceiptDownloadText
        vatReceiptDownloadUrl
      }
    } 
  }
`

const BillingHistoryZone = (props: BillingHistoryZoneType) => {
  return <BillingHistory billingHistories={props.allBillingHistories} />
}

export default BillingHistoryZone
