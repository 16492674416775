import React, { useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { useIntersectionObserver } from '../hooks/useIntersectionObserver'

interface VideoPlayerProps {
  videoSrc: string
  isControlled?: boolean
  isAutoLooping?: boolean
}

export function VideoPlayer({
  videoSrc,
  isControlled,
  isAutoLooping,
}: VideoPlayerProps) {
  const ref = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(ref, {})
  let isVisible = entry?.isIntersecting
  const [isPaused, setIsPaused] = useState(false)

  if (isPaused) {
    isVisible = false
  }

  const handlePause = () => {
    setIsPaused(true)
  }

  return (
    <div ref={ref} className="pb-9/16 relative w-full">
      <ReactPlayer
        className="absolute h-full top-0 w-full"
        url={videoSrc}
        controls={true}
        loop={isAutoLooping}
        playing={isVisible}
        width="100%"
        height="100%"
        muted={true}
        onPause={handlePause}
      />
    </div>
  )
}
