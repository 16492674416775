import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import { getStrapiMedia } from '../../../lib/strapi/helpers'
import { ImageType } from '../../../lib/strapi/responseTypes'
import { ImageSection } from '../../ImageSection'
import { Markdown } from '../../Markdown'

export type ImageSectionZoneType = DynamicBlockZone<
  'ComponentContentCardsImageSection',
  {
    title: string
    richContent: string
    imageLandscape: {
      image: ImageType
    }
  }
>

export const ProgressBarZoneFragment = `
  ... on ComponentContentCardsImageSection {
        __typename
        title
        richContent
        imageLandscape {
          image {
            url
            alternativeText
          }
        }
      }
`

const ImageSectionZone = (props: ImageSectionZoneType) => {
  return (
    <ImageSection
      title={props.title}
      imgSrc={getStrapiMedia(props.imageLandscape.image.data.attributes.url)}
      alt={props.imageLandscape.image.data.attributes.alternativeText}
    >
      <Markdown source={props.richContent} isForBlog={false} />
    </ImageSection>
  )
}

export default ImageSectionZone
