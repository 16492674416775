
import Script from 'next/script';

export function SendItLetterWrapper() {
  return (
    <>
    <Script
    src="https://actionnetwork.org/widgets/v5/letter/send-it-for-climate-virtual-mp-postcard?format=js&source=widget"
    onLoad={() => {
        console.log('Script has loaded')
      }}/>
        <div id='can-letter-area-send-it-for-climate-virtual-mp-postcard' className='md:pl-12 lg:pl-24 mr-16'></div>    
        </>
  )
}
