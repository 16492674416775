import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import { TextTrio, TextTrioItem } from '../../TextTrio'
import { LinkType, HeadingType } from '../../../lib/strapi/responseTypes'
import { Heading, Style, Variant } from '../../Heading'
import { Markdown } from '../../Markdown'
import { VideoPlayer } from '../../VideoPlayer'

export type VideoZoneType = DynamicBlockZone<
  'ComponentMediaTypesVideo',
  {
    url: string
    autoloop: boolean
  }
>

export const VideoZoneFragment = `
   ... on ComponentMediaTypesVideo {
        __typename
       url
       autoloop
      }
`

const VideoZone = (props: VideoZoneType) => {
  return (
    <VideoPlayer videoSrc={props.url} isAutoLooping={props.autoloop} />
  )
}

export default VideoZone
