import NextLink from 'next/link'
import Image from 'next/image'
import cn from 'classnames'
interface PartnerLogoProps {
  url: string
  src: string
  alt?: string
  classnames?: string
}

export function PartnerLogo({ url, src, alt, classnames }: PartnerLogoProps) {
  return (
    <div className={cn(classnames, 'bg-white px-2 py-2')}>
      <NextLink href={url}>
        <a
          className="flex flex-col h-46 justify-center relative"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src={src} 
          objectFit="contain" 
          alt={alt} 
          layout="fill"
          loader={({ src }) => {
            return src;
          }} />
        </a>
      </NextLink>
    </div>
  )
}
