import classNames from 'classnames'
import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import { HeadingType } from '../../../lib/strapi/responseTypes'
import { Heading, Style, Variant } from '../../Heading'

export type Heading1ZoneType = DynamicBlockZone<
  'ComponentHeadingsH1',
  HeadingType
>

export type Heading2ZoneType = DynamicBlockZone<
  'ComponentHeadingsH2',
  HeadingType
>

export type Heading3ZoneType = DynamicBlockZone<
  'ComponentHeadingsH3',
  HeadingType
>

export type Heading4ZoneType = DynamicBlockZone<
  'ComponentHeadingsH4',
  HeadingType
>

const headingQuery = `
      __typename
      headingText `

export const Heading1ZoneFragment = `
  ... on ComponentHeadingsH1 {
     ${headingQuery}
  }
`

export const Heading2ZoneFragment = `
  ... on ComponentHeadingsH2 {
    __typename 
       ${headingQuery}
  }
`

export const Heading3ZoneFragment = `
  ... on ComponentHeadingsH3 {
    __typename 
       ${headingQuery}
  }
`

export const Heading4ZoneFragment = `
  ... on ComponentHeadingsH4 {
    __typename 
       ${headingQuery}
  }
`

// Extract this to solve a sonarCloud issue
const centeredHeadingClassNames = (isCentered: boolean) => {
  return isCentered ? 'text-center' : ''
}

export const Heading1Zone = (props: Heading1ZoneType) => {
  return (
    <Heading
      className={classNames(
        `mb-${props.additionalBottomSpace}`,
        centeredHeadingClassNames(props.isCentered)
      )}
      headingText={props.headingText}
      variant={Variant.h1}
      cssStyle={Style.h1}
    />
  )
}

export const Heading2Zone = (props: Heading2ZoneType) => {
  return (
    <Heading
      className={classNames(
        `mb-${props.additionalBottomSpace}`,
        centeredHeadingClassNames(props.isCentered)
      )}
      headingText={props.headingText}
      variant={Variant.h2}
      cssStyle={Style.h2}
    />
  )
}

export const Heading3Zone = (props: Heading3ZoneType) => {
  return (
    <Heading
      className={classNames(
        `mb-${props.additionalBottomSpace}`,
        centeredHeadingClassNames(props.isCentered)
      )}
      headingText={props.headingText}
      variant={Variant.h3}
      cssStyle={Style.h3}
    />
  )
}

export const Heading4Zone = (props: Heading4ZoneType) => {
  return (
    <Heading
      className={classNames(
        `mb-${props.additionalBottomSpace}`,
        centeredHeadingClassNames(props.isCentered)
      )}
      headingText={props.headingText}
      variant={Variant.h4}
      cssStyle={Style.h4}
    />
  )
}
