import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import  MerchandiseCard  from '../../MerchandiseCard'
import { Heading, Style, Variant } from '../../Heading'
import { Markdown } from '../../Markdown'
import { Link as LinkComponent, LinkVariant } from '../../Link'
import { ImageType, LinkType, Product, Products } from '../../../lib/strapi/responseTypes'
import { POWProduct } from '../../POWProduct'
import { getStrapiMedia } from '../../../lib/strapi/helpers'
export type MerchandiseCardZoneType = DynamicBlockZone<
  'ComponentContentCardsMerchandiseCard',
  {
    merchandises:Products
  }
>

export const MerchandiseCardZoneFragment = `
... on ComponentContentCardsMerchandiseCard {
  __typename
   merchandises {
    data {
    attributes {
      productName
      productImage {
        data {
          attributes {
            url
            alternativeText
          }
        }
      }
      price
      productLink{
        href
        label
        variant
      }
      inStock
    }
  }
  }
}

`

const MerchandiseCardZone = ( props: MerchandiseCardZoneType) => {
  return (
    
   
    <div>
      <MerchandiseCard>
      {props.merchandises.data.map((product) => (
        <POWProduct
          imgSrc={getStrapiMedia(product.attributes.productImage.data.attributes.url)}
          imgAlt={product.attributes.productImage.data.attributes.alternativeText}
          productName={product.attributes.productName}
          price={product.attributes.price}
          buyingLink={product.attributes.productLink.href}
          isOutOfStock={!product.attributes.inStock}
        />
      ))} 
      
      </MerchandiseCard>
    </div>
  )
}

export default MerchandiseCardZone
