import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import { Variant as ButtonVariant } from '../../Button'
import { ReportYourProgress } from '../../ReportYourProgress'
import SendItLetter from '../../SendItLetter'
import SendItPostcard from '../../SendItPostcard'

export type SendItLetterZoneType = DynamicBlockZone<
  'ComponentFormSendItLetter',
  {
    Body: string
  }
>

export const SendItLetterZoneFragment = `
  ... on ComponentFormSendItLetter {
    __typename
    Body
}
`

const SendItLetterZone = (props: SendItLetterZoneType) => {
    return (
        <SendItLetter body={props.Body}></SendItLetter>
    )
}

export default SendItLetterZone



